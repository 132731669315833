import { DeploymentStageName, LogLevel, TargetUserGroup } from '@trustedshops/tswp-core-common';

export const environment = {
  production: false,
  targetGroup: TargetUserGroup.Employees,
  name: DeploymentStageName.Integr,
  pageTitle: 'eTrusted Control Centre',
  apis: {
    plugins: 'https://app-api.etrusted.koeln/v0'
  },
  diagnostics: {
    logging: {
      logLevels: [
        // LogLevel.Trace,
        LogLevel.Debug,
        LogLevel.Information,
        LogLevel.Warning,
        LogLevel.Error,
        LogLevel.Fatal
      ],
      filters: [],
      sentry: {
        environment: 'integr',
        logLevels: [
          LogLevel.Error,
          LogLevel.Fatal
        ],
        dsn: 'https://2261940b9ac14057bbc6880eeeedd925@o128203.ingest.sentry.io/5631748',
        browserTracingOrigins: [
          'localhost:6860',
          'employees-integr.trustedshops.com',
          'employees-api-integr.trustedshops.com',
          'employees.etrusted.koeln',
          'employees-api.etrusted.koeln'
        ],
        sentryConfig: {
          maxBreadcrumbs: 10
        }
      },
      instana: {
        logLevels: [
          LogLevel.Error,
          LogLevel.Fatal,
        ],
        browserTracingOrigins: [],
        reportingUrl: 'https://eum-blue-saas.instana.io',
        key: 'NoXRTxZ3SdWkAwGqNjVOiQ',
        scriptUrl: 'https://eum.instana.io/eum.min.js',
      },
    }
  },
  authorization: {
    keycloakUrl: 'https://sso-integr.trustedshops.com/auth',
    clientId: 'tswp-carrier-employees',
    realm: 'ts-sso-INTEGR',
    sessionVerificationType: 'check-sso',
    silentCheckSsoRedirectUri: '/assets/authentication/token-proxy.html'
  },
  layout: {
    resources: {
      heliosUri: 'https://employees-integr.trustedshops.com/helios.css',
      heliosIconUri: 'https://cdn-integr.trustedshops.com/helios/helios-icons/1.7.20/raw/'
    }
  },
  storage: {
    plugins: {
      rootPath: 'https://employees-integr.trustedshops.com/plugins'
    }
  }
};
